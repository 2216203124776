<script setup lang="ts">
const { isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route = useRoute();

console.log("route:", route);
const rName: any = route.name || "";
console.log("rName:", rName);
if (hasLength(rName)) title.value = rName.toUpperCase();
console.log("title.value:", title.value);
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeader class="max-w-full p-[16px]" />
  <section id="oneCol" class="relative h-full max-w-full scrollbar-hide">
    <NuxtNotifications
      classes="notify"
      :style="`position: relative;`"
      width="auto"
      :duration="-5000"
      :pause-on-hover="true"
      :max="1"
    ></NuxtNotifications>
    <slot />
  </section>
</template>
